<template>
<div id="app">
    <div>
        <div class="md:min-h-56 bg-white dark:bg-gray-900 py-4 px-4 flex flex-col justify-between flex-wrap">
            <Carousel :settings="settings" :autoplay="6000" :wrap-around="true" :breakpoints="breakpoints" >
            <Slide v-for="slide in carouselSlides" :key="slide" >
              <div v-show="carouselSlides" >
                <img :src="require(`@/assets/images/banner/${slide}.jpg`)" alt=""  />
              </div>
            </Slide>
            <template #addons>
                <Pagination  />
            </template>
            </Carousel>
        </div>
        <div class="mt-8 px-3 py-10 bg-gray-50">
            <h3 class="mt-1 text-2xl text-center  font-medium tracking-tight text-black sm:text-3xl">Portofolio</h3>
            <!-- component -->
            <div class="container mt-4 mb-2 flex mx-auto w-full items-center justify-center">
				<ul class="flex flex-col gap-y-4 px-4 py-4 text-center">
									<li class="border-purple-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Manajemen Bengkel
												</div>
											</div>
										</div>
									</li>
									<li class="border-gray-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Inventory Asset
												</div>
											</div>
										</div>
									</li>	
																		<li class="border-purple-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Manajemen Laporan 
												</div>
											</div>
										</div>
									</li>
									<li class="border-gray-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Manajemen Order 
												</div>
											</div>
										</div>
									</li>		
									<li class="border-purple-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Pendaftaran Online Siswa
												</div>
											</div>
										</div>
									</li>
									<li class="border-gray-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Manajemen Laboratorium
												</div>
											</div>
										</div>
									</li>
									<li class="border-purple-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Mini Job Fair Online
												</div>
											</div>
										</div>
									</li>
									<li class="border-gray-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Manajemen Puskesmas
												</div>
											</div>
										</div>
									</li>
									<li class="border-purple-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													E-commerce Toko Pertanian
												</div>
											</div>
										</div>
									</li>
									<li class="border-gray-400 flex flex-row">
										<div
											class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-purple-400"
										>
											<div class="flex-1 pl-1 mr-16">
												<div class="font-medium">
													Jurnal Pendidikan
												</div>
											</div>
										</div>
									</li>					
				</ul>
			</div>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { Carousel,Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default defineComponent({
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Pagination
  },
  data: () => ({
    // carousel settings
    modelValue:'',
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    carouselSlides:[
      "3","4","5","6"
    ],
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
    },
  }),
});
</script>
